<template>
    <div class="dashboard" :style="`--var-radio-background-color: ${ progressTracking.public_settings.element_color ? progressTracking.public_settings.element_color : '' };`">
        <div class="client_card">
            <div v-show="progressTracking.public_settings.display_header == 1" class="card_header pointer" @click="handleSetting('hdrsetting')" ref="hdrsection" :style="`background: ${progressTracking.public_settings.header_bgcolor}; color: ${progressTracking.public_settings.header_textcolor};`">
                <nav>
                    <!-- <h4>{{ progressTracking.public_settings.company_branding }}</h4> -->
                    <img class="logo_img" :src="progressTracking.public_settings.logo ? progressTracking.public_settings.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                    <a>
                        <h4>Dashboard</h4>
                    </a>
                </nav>
                <div class="user_box" @click="accountDrop = !accountDrop">
                    <img :src="progressTracking.public_settings.author ? progressTracking.public_settings.author : user.profile_pic" :alt="user.full_name">
                    <div class="user_info">
                        <h5 :style="`color: ${progressTracking.public_settings.header_textcolor};`">{{ user.full_name }} <i class="fas fa-caret-down" :class="`${accountDrop ? 'swing' : ''}`"></i></h5>
                    </div>
                    <ul class="account_drop text-inherit" :class="{ active : accountDrop }">
                        <li><i class="fas fa-home"></i> Primary Location</li>
                        <li><i class="far fa-user"></i> Profile Info</li>
                        <li><i class="far fa-file"></i> My Files</li>
                        <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                    </ul>
                </div>
            </div>
            <div class="card_body" :class="device != 'desk' ? ( device == 'tab' && progressTracking.public_settings.cover_type == 1 ? 'cover-image-md' : (progressTracking.public_settings.cover_type == 1 ? 'cover-image-sm' : '')) :'cover-image'" @click="handleSetting('cvrsetting')" ref="cvrsection" v-show="progressTracking.public_settings.display_cover && progressTracking.public_settings.has_small_cover">
                <!-- <picture v-if="(progressTracking.public_settings.cover_type == 2 || progressTracking.public_settings.cover_type == 3)">
                    <source v-if="progressTracking.public_settings.has_small_cover && progressTracking.public_settings.small_cover != null && progressTracking.public_settings.small_cover != '' && progressTracking.public_settings.small_cover != 'null'" :srcset="`${progressTracking.public_settings.small_cover}`" media="(max-width: 768px)">
                    <img v-if="device == 'desk'" :src="progressTracking.public_settings.cover ? progressTracking.public_settings.cover : require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner_img">
                    <img v-else :src="progressTracking.public_settings.has_small_cover && progressTracking.public_settings.small_cover ? progressTracking.public_settings.small_cover : (progressTracking.public_settings.cover ? progressTracking.public_settings.cover : require('@/assets/images/thumb/default-cover-3-2.svg'))" alt="" class="banner_img">
                </picture> -->
                <picture v-if="(progressTracking.public_settings.cover_type == 2 || progressTracking.public_settings.cover_type == 3)">
                    <source v-if="progressTracking.public_settings.small_cover != null && progressTracking.public_settings.small_cover != '' && progressTracking.public_settings.small_cover != 'null'" :srcset="`${progressTracking.public_settings.small_cover}`" media="(max-width: 768px)">
                    <img v-if="device == 'desk'" :src="progressTracking.public_settings.cover ? progressTracking.public_settings.cover : require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner_img">
                    <img v-else :src="progressTracking.public_settings.small_cover ? progressTracking.public_settings.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                </picture>
                <span v-if="progressTracking.public_settings.cover_type == 1" class="overlay" :style="`background-color: ${progressTracking.public_settings.cover_color ? progressTracking.public_settings.cover_color : progressTracking.public_settings.overlay_color};`"></span>
                <span v-else-if="progressTracking.public_settings.cover_type == 2 && progressTracking.public_settings.has_cover_overlay" class="overlay" :style="`background-color: ${progressTracking.public_settings.overlay_color}; opacity: ${(progressTracking.public_settings.opac_overlay_color == 'transparent' || !progressTracking.public_settings.has_cover_overlay || progressTracking.public_settings.overlay_opacity == 0) ? '0': ((progressTracking.public_settings.overlay_opacity / 100 ))};`"></span>
                <span v-else-if="progressTracking.public_settings.cover_type == 3 && progressTracking.public_settings.has_cover_overlay" class="overlay" :style="`background-color: ${progressTracking.public_settings.overlay_color}; opacity: ${(progressTracking.public_settings.opac_overlay_color == 'transparent' || !progressTracking.public_settings.has_cover_overlay || progressTracking.public_settings.overlay_opacity == 0) ? '0': ((progressTracking.public_settings.overlay_opacity / 100 ))};`"></span>
                <div class="banner_content" v-if="device == 'desk'">
                    <h4 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ ( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? progressTracking.headline_setting.font_size : ''}px; line-height: ${( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? parseInt(progressTracking.headline_setting.font_size) + 20 : ''}px; font-weight: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_weight}; font-family: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_family}; color: ${progressTracking.headline_setting.font_color}; ${ progressTracking.headline_setting && progressTracking.headline_setting.has_shadow ? 'text-shadow: ' + progressTracking.headline_setting.shadow_x +'px ' + progressTracking.headline_setting.shadow_y + 'px ' + progressTracking.headline_setting.shadow_blur + 'px ' + progressTracking.headline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.headline }}</h4>
                    <h1 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_size : ''}px; line-height: ${ progressTracking.subheadline_setting ? (parseInt(progressTracking.subheadline_setting.font_size) + 20) : ''}px; font-weight: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_weight : ''}; font-family: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_family : ''}; color: ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.font_color ? progressTracking.subheadline_setting.font_color : ''}; ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.has_shadow ? 'text-shadow: ' + progressTracking.subheadline_setting.shadow_x +'px ' + progressTracking.subheadline_setting.shadow_y + 'px ' + progressTracking.subheadline_setting.shadow_blur + 'px ' + progressTracking.subheadline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.subheadline }}</h1>
                </div>
                <div class="banner_content" v-if="device == 'tab'">
                    <h4 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ ( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? progressTracking.headline_setting.font_size / 2 : ''}px; line-height: ${( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? (parseInt(progressTracking.headline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_weight}; font-family: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_family}; color: ${progressTracking.headline_setting.font_color}; ${ progressTracking.headline_setting && progressTracking.headline_setting.has_shadow ? 'text-shadow: ' + progressTracking.headline_setting.shadow_x +'px ' + progressTracking.headline_setting.shadow_y + 'px ' + progressTracking.headline_setting.shadow_blur + 'px ' + progressTracking.headline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.headline }}</h4>
                    <h1 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_size / 1.5 : ''}px; line-height: ${ progressTracking.subheadline_setting ? (parseInt(progressTracking.subheadline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_weight : ''}; font-family: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_family : ''}; color: ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.font_color  ? progressTracking.subheadline_setting.font_color : ''}; ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.has_shadow ? 'text-shadow: ' + progressTracking.subheadline_setting.shadow_x +'px ' + progressTracking.subheadline_setting.shadow_y + 'px ' + progressTracking.subheadline_setting.shadow_blur + 'px ' + progressTracking.subheadline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.subheadline }}</h1>
                </div>
                <div class="banner_content" v-if="device == 'cell'">
                    <h4 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ ( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? (progressTracking.headline_setting.font_size / 3 < 20 ? 20 : progressTracking.headline_setting.font_size / 3) : ''}px; line-height: ${( progressTracking.headline_setting && progressTracking.headline_setting.font_size) ? (progressTracking.headline_setting.font_size / 3 < 20 ? 26 :(parseInt(progressTracking.headline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_weight}; font-family: ${ progressTracking.headline_setting && progressTracking.headline_setting.font_family}; color: ${progressTracking.headline_setting.font_color}; ${ progressTracking.headline_setting && progressTracking.headline_setting.has_shadow ? 'text-shadow: ' + progressTracking.headline_setting.shadow_x +'px ' + progressTracking.headline_setting.shadow_y + 'px ' + progressTracking.headline_setting.shadow_blur + 'px ' + progressTracking.headline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.headline }}</h4>
                    <h1 v-if="progressTracking.public_settings.cover_type == 1 || progressTracking.public_settings.cover_type == 2" :style="`font-size: ${ progressTracking.subheadline_setting ? (progressTracking.subheadline_setting.font_size / 3 < 11 ? 11 : progressTracking.subheadline_setting.font_size / 3) : ''}px; line-height: ${ progressTracking.subheadline_setting ? (progressTracking.subheadline_setting.font_size / 3 < 11 ? 16 : (parseInt(progressTracking.subheadline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_weight : ''}; font-family: ${ progressTracking.subheadline_setting ? progressTracking.subheadline_setting.font_family : ''}; color: ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.font_color  ? progressTracking.subheadline_setting.font_color : ''}; ${ progressTracking.subheadline_setting && progressTracking.subheadline_setting.has_shadow ? 'text-shadow: ' + progressTracking.subheadline_setting.shadow_x +'px ' + progressTracking.subheadline_setting.shadow_y + 'px ' + progressTracking.subheadline_setting.shadow_blur + 'px ' + progressTracking.subheadline_setting.shadow_color : 'none'}`">{{ progressTracking.public_settings.subheadline }}</h1>
                </div>
            </div>
        </div>
        <div class="dashboard_content py-5">
            <div class="form_area_video">
                <div class="form_area" @click="handleSetting('thankyousetting')" ref="thankyousection" :style="`color: ${progressTracking.public_settings.thankyou_textcolor}; background: ${progressTracking.public_settings.thankyou_bgcolor};`">
                      <div class="setting_wpr" :style="`color: ${progressTracking.public_settings.thankyou_textcolor};`">
                          <h5>Progress report submitted! You will now be able to view your updated progress in your <a href="javascript:void(0);" class="link" :style="`color: ${progressTracking.public_settings.thankyou_textcolor};`">progress dashboard</a>.</h5>
                      </div>
                </div>
            </div>
        </div>
        <footer class="dashboard_footer" v-show="progressTracking.public_settings.display_footer == 1" @click="handleSetting('ftrsetting')" ref="ftrsection" :style="`background: ${progressTracking.public_settings.footer_bgcolor};`">
            <ul>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_facebook"><a :href="progressTracking.public_settings.footer_facebook" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_twitter"><a :href="progressTracking.public_settings.footer_twitter" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_instagram"><a :href="progressTracking.public_settings.footer_instagram" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_linkedin"><a :href="progressTracking.public_settings.footer_linkedin" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                <li class="pointer" v-show="progressTracking.public_settings.footer_has_youtube"><a :href="progressTracking.public_settings.footer_youtube" :style="`border-color: ${progressTracking.public_settings.footer_textcolor}; color: ${progressTracking.public_settings.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
            </ul>
            <h4 :style="`color: ${progressTracking.public_settings.footer_textcolor};`">{{ progressTracking.public_settings.footer_company ?  progressTracking.public_settings.footer_company : `© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}` }}</h4>
            <p>
                <a :href="progressTracking.public_settings.footer_terms" :style="`color: ${progressTracking.public_settings.footer_textcolor};`">Terms</a>
                <a :href="progressTracking.public_settings.footer_policy" :style="`color: ${progressTracking.public_settings.footer_textcolor};`">Privacy</a>
                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                    <a v-if="whiteLabel.has_link == 1" :style="`color: ${progressTracking.public_settings.footer_textcolor};`" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                    <a v-else :style="`color: ${progressTracking.public_settings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                </template>
                <a v-else :style="`color: ${progressTracking.public_settings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
            </p>
        </footer>
    </div>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        name: 'Progress Tracking Thankyou Preview Content',

        data () {
            return {
                accountDrop: false,
                whiteLabel: {},
            }
        },

        props: {
            progressTracking: Object,
            device: {
                type: String,
                default: 'cell'
            },
            isNew: Boolean,
        },

        emit: [ 'update:modelValue' ],

        computed: mapState({
            user: state => state.authModule.user,
            membership: state => state.authModule.membership,
        }),

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
        },

        methods:{
            accFunc (e) {
                let el = e.currentTarget.closest('.quest_wpr');
                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    el.classList.add('active');
                }
            },

            goTo (refName) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    setTimeout(function() {
                        if (element) {
                            let top = element.offsetTop;
                            document.querySelector('.preview_content .content_area').scrollTo(0, top);
                        }
                    }, 100);
                }, 10);
            },

            handleSetting(val) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                if (vm.$parent.$parent && vm.$parent.$parent.setting) {
                    vm.$parent.$parent.setting(val);
                } else {
                    vm.$parent.setting(val);
                }
            }
        }
    }
</script>

<style scoped>
    .tab .dashboard_content {
        margin: 0;
    }

    .cell .dashboard_content {
        flex: 1 1 auto;
        margin: 0;
        padding: 0 15px;
        min-height: 35vh;
    }

    .quest_wpr {
        border: 1px solid #e9e9e9;
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .category_title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;
        background: #fff;
        font-size: 16px;
        line-height: 21px;
        color: #121525;
        font-weight: 500;
        padding: 15px 30px;
    }

    .tab .category_title {
        font-size: 14px;
        line-height: 19px;
        padding: 15px 20px;
    }

    .category_title i {
        margin-top: 4px;
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .quest_wpr.active .category_title i {
        transform: rotate(-180deg);
    }

    .category_details {
        border-top: 1px solid #e9e9e9;
        background: #fff;
        margin: 0;
        padding: 15px 30px;
        display: none;
    }

    .quest_wpr.active .category_details {
        display: block;
    }

    .require_wpr {
        background: #f5f5f5;
        padding: 10px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        margin: 10px 0;
    }

    .require_wpr h4 {
        font-size: 16px;
        line-height: 20px;
        color: 32373b;
        font-weight: 500;
    }

    .tab .require_wpr h4 {
        font-size: 14px;
        line-height: 19px;
    }

    .require_wpr p {
        font-size: 14px;
        line-height: 19px;
        color: #5a5a5a;
        padding: 5px 0;
    }

    .tab .require_wpr p {
        font-size: 12px;
        line-height: 17px;
    }

    .client_card {
        width: 100%;
        border: 0;
    }

    .client_card .card_body h1 {
        font-size: 60px;
        line-height: 75px;
        text-transform: capitalize;
        text-align: center;
        margin-bottom: 15px;
    }

    .tab .client_card .card_body h1 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .client_card .card_body h4 {
        font-size: 28px;
        line-height: 38px;
        text-transform: capitalize;
        text-align: center;
        letter-spacing: 0;
        font-weight: 500;
    }

    .tab .client_card .card_body h4 {
        font-size: 18px;
        line-height: 25px;
    }

    .dashboard_footer {
        width: 100%;
        text-align: center;
    }

    .grp_wpr {
        display: flex;
        margin: 0 -15px;
    }

    .box_item {
        flex: 1 0 50%;
        padding: 5px 15px;
    }

    .grp_wpr label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
    }

    .picture_section{
        display: flex;
        flex-wrap: wrap;
        margin: 15px -15px;
    }
    .picture_section > li{
        padding: 5px 15px;
        width: 33.333%;
    }
    .picture_section > li .section_wpr{
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        overflow: hidden;
    }
    .picture_section .section_wpr li .section_title{
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        background: #f9f9f9;
    }
    .picture_section .section_wpr li .section_title h4{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        text-align: left;
    }
    .picture_section .section_wpr li img{
        padding: 15px;
        width: 100%;
    }

    .day_sorting {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -5px;
    }

    .day_sorting li {
        padding: 5px;
        width: 25%;
        min-width: 90px;
    }

    .day_sorting li label {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
    }

    .section_wpr h3 {
        font-size: 20px;
        line-height: 28px;
    }

    h3.sub_header {
        font-size: 20px;
        line-height: 23px;
        margin: 30px 0 12px;
    }

    .tab .dashboard_content h3.sub_header {
        font-size: 18px;
        line-height: 21px;
        margin: 30px 0 12px;
    }

    .cell .dashboard_content h3.sub_header {
        font-size: 15px;
        line-height: 18px;
        margin: 30px 0 8px;
    }

    .goal_btn {
        background: #f2a31d;
        padding: 12px 15px;
        font-size: 17px;
        line-height: 22px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        border-radius: 8px;
        text-align: center;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        margin: 20px 0;
    }

    .goal_btn i {
        margin-right: 15px;
        color: #32373b;
        font-size: 22px;
    }

    .primary_btn {
        margin: 30px 0;
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: pointer;
    }

    /* Page Preview */
    .cell .dashboard {
        margin: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .cell .client_card {
        border-radius: 6px;
    }

    .cell .client_card .card_header {
        padding: 15px 20px;
    }

    .cell .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .cell .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 14px;
    }

    /* .cell .client_card .card_header h4 {
        font-size: 14px;
        line-height: 20px;
    } */

    .cell .client_card .card_body {
        text-align: center;
    }

    .cell .client_card .card_body .banner_content {
        padding: 0 15px;
    }

    .cell .client_card .card_body h1 {
        font-size: 17px;
        line-height: 30px;
        margin: 0;
        font-weight: 600;
        color: #fff;
        /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); */
        /* letter-spacing: 1px; */
        position: relative;
        z-index: 3;
    }

    .cell .client_card .card_body h4 {
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: #fff;
        /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        /* letter-spacing: 0; */
        text-transform: initial;
        position: relative;
        z-index: 3;
    }

    .cell .client_card .card_body .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    .cell .form_area {
        max-width: 400px;
        border-radius: 6px;
    }

    .cell .quest_wpr {
        border-radius: 5px;
    }

    .cell .form_grp {
        padding: 10px 0;
    }

    .cell .grp_wpr {
        display: flex;
        margin: 0 -7px;
    }

    .cell .box_item {
        flex: 1 0 50%;
        padding: 2px 7px;
    }

    .cell .setting_wpr .group_item .input_label {
        font-size: 11px;
        line-height: 13px;
    }

    .cell .grp_wpr label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 5px 7px;
        display: flex;
        align-items: center;
        font-size: 70%;
    }

    .cell .field_wpr input {
        height: 26px;
        font-size: 11px;
    }

    .cell .field_wpr textarea {
        height: 52px;
        font-size: 11px;
        padding: 5px 10px;
    }

    .cell .field_wpr.has_suffix .suffix {
        font-size: 10px;
    }

    .cell .category_title {
        font-size: 12px;
        line-height: 17px;
        padding: 10px 15px;
    }

    .cell .category_details {
        padding: 7px 15px;
    }

    .cell .category_details .section_wpr h3 {
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 0;
    }

    .cell .require_wpr h4 {
        font-size: 12px;
        line-height: 15px;
    }

    .cell .require_wpr p {
        font-size: 10px;
        line-height: 13px;
    }

    .cell h3.sub_header {
        font-size: 16px;
        line-height: 20px;
        margin: 20px 0 10px;
    }

    .cell .day_sorting li {
        min-width: 75px;
    }

    .cell .day_sorting li label {
        padding: 5px 10px;
    }

    .cell .day_sorting li label p {
        font-size: 10px;
    }

    .cell .goal_btn {
        padding: 7px 10px;
        font-size: 11px;
        line-height: 14px;
        border-radius: 4px;
    }

    .cell .goal_btn i {
        font-size: 14px;
    }

    .cell .day_sorting li label span {
        transform: scale(0.8);
    }

    .cell .upload_image label {
        padding: 10px;
    }

    .cell .upload_image .icon {
        max-height: 40px;
        width: auto;
    }

    .cell .upload_image h4 {
        font-size: 10px;
        line-height: 12px;
        margin-top: 7px;
    }

    .cell .primary_btn {
        height: 35px;
        letter-spacing: 1px;
        font-size: 13px;
        margin: 25px 0;
    }

    :deep(.video_wpr) {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
    }

    :deep(.video_wpr iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    :deep(.tab_content) {
        height: 100%;
    }

    .grp_wpr .box_item input[type='radio'] {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        outline: none;
        border: 2px solid var(--var-radio-background-color);
    }

    .grp_wpr .box_item input[type='radio']:before {
        content: '';
        display: block;
        width: 80%;
        height: 80%;
        margin: 10%;
        border-radius: 50%;
    }

    .grp_wpr .box_item input[type="radio"]:checked:before {
        background: var(--var-radio-background-color);
    }

    .grp_wpr .box_item input[type="radio"]:checked {
        border-color: var(--var-radio-background-color);
    }

    .opt_item {
        flex: 0 0 30%;
        padding: 5px 15px;
    }

    .cell .opt_item {
        flex: auto;
        padding: 0px 2px;
    }

    .opt_item.Week_btn {
        flex-basis: 100%;
    }

    .success_goal_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
        border-radius: 6px;
        background: #2F7FED;
        color: #fff;
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        width: 100%;
        border: 0;
    }

    .form_grp .opt_item input[type='checkbox'] {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        outline: none;
        border: 2px solid var(--var-radio-background-color);
    }

    .form_grp .opt_item input[type='checkbox']:before {
        content: '';
        display: block;
        width: 80%;
        height: 80%;
        margin: 10%;
        border-radius: 50%;
    }

    .form_grp .opt_item input[type="checkbox"]:checked:before {
        background: var(--var-radio-background-color);
    }

    .form_grp .opt_item input[type="checkbox"]:checked {
        border-color: var(--var-radio-background-color);
    }

    .cell .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
        object-fit: contain;
    }

    .client_card .card_header .user_box {
        margin-left: auto;
    }


    .cell .form_area_video {
        max-width: 800px;
        margin: 0px;
        flex: 1;
    }

    .form_area_video {
        max-width: 800px;
        margin: 20px auto 50px auto;
        flex: 1;
    }

    .form_area {
        max-width: 800px;
        padding: 30px 40px;
        /* border: 1px solid #e0e0e0; */
        /* margin: 40px auto; */
        flex: 1;
        border-radius: 12px;
        /* box-shadow: 0 0 35px rgba(0,0,0,0.03); */
    }
    .form_area h5{
        font-size: 14px;
        line-height: 23px;
        font-weight: 400;
        text-align:center;
    }
    .cell .form_area {
        max-width: 400px;
        padding: 15px;
        border-radius: 6px;
        /* box-shadow: 0 0 20px rgba(0,0,0,0.03); */
    }

    .cell .edit_section {
        padding: 10px 15px;
    }

    .cell .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
        margin-top: 30px;
    }

    .cell .dashboard_footer ul li {
        padding: 2px;
    }

    .cell .dashboard_footer ul li img {
        width: 20px;
        height: 20px;
    }

    .cell .dashboard_footer h4  {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    .cell .dashboard_footer p {
        font-size: 9px;
        line-height: 11px;
    }

    .cell .dashboard_content h3 {
        font-size: 17px;
        line-height: 23px;
    }

    .cell .picture_section {
        margin: 5px -7px;
    }

    .cell .picture_section > li {
        padding: 12px 7px;
    }

    .cell .picture_section .section_wpr li .section_title {
        padding: 10px 15px;
    }

    .cell .picture_section .section_wpr li .section_title h4 {
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
    }

    .cell .picture_section .section_wpr li img {
        padding: 0;
    }

    .grp_wpr.grp_option {
        display: flex;
        flex-wrap: wrap;
    }

    .cell .dashboard_content .field_wpr :deep(textarea){
        height: 45px;
        font-size: 10px;
        line-height: 12px;
        padding: 5px 10px;
    }

    :deep(.cell .tab_content .upload_wpr .info) {
        text-align: center;
    }

    :deep(.cell .tab_content .upload_wpr img) {
        max-width: 70%;
        height: auto;
        font-size: 9px;
        line-height: 15px;
        color: #747474;
        font-weight: 500;
        margin: 10px 0;
    }

    :deep(.cell .upload_area h4) {
        font-size: 9px;
        line-height: 15px;
        color: #575757;
        font-weight: 500;
        margin: 14px 0 0;
        display: block!important;
    }

    :deep(.cell .upload_wpr .upload_area) {
        height: 110px;
        min-height: 80px;
    }
    .field_wpr.has_suffix{
        padding-right: 100px;
    }
    .field_wpr.has_suffix .suffix{
        width: 100px;
    }
    .picture_section .upload_wpr .upload_area{
        border-radius: 0;
    }
    .picture_section :deep(.upload_wpr img){
        width: 70px;
        height: auto;
    }
    .picture_section :deep(.upload_wpr h4){
        font-size: 13px;
        line-height: 16px;
    }
    .picture_section :deep(.upload_wpr .info){
        text-align: center;
    }
    .tab .picture_section :deep(.upload_wpr img){
        width: 50px;
        height: auto;
    }
    .tab .picture_section :deep(.upload_wpr h4){
        font-size: 11px;
        line-height: 15px;
    }
    .tab .picture_section :deep(.upload_wpr .info){
        font-size: 10px;
        line-height: 12px;
    }
    .cell .picture_section > li{
        width: 100%;
    }

    a.link {
        text-decoration: none;
        font-weight: 500;
    }
</style>
